import { Button, Text, Icon } from '@lemonenergy/lemonpie'
import { Link as RemixLink } from '@remix-run/react'
import styled, { css } from 'styled-components'

type PageVariant = 'primary' | 'neutral'

interface ErrorPageProps {
  label?: string
  onClick?: () => void
  href?: string
  text: string
  /**
   * Especifies the page's colors variation
   */
  variant?: PageVariant
}

const Container = styled.div<{ variant: PageVariant }>(
  ({ variant, theme: { colors, spacing } }) => {
    const backgroundColor =
      variant === 'primary' &&
      css`
        background-color: ${colors.primary.main};
      `

    return css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 100%;

      ${backgroundColor}
      color: ${variant === 'primary'
        ? colors.neutral.lightest
        : colors.neutral.dark};

      ${Icon} {
        margin-bottom: ${spacing(3.375)};
      }

      > ${Text} {
        max-width: 275px;
        text-align: center;
      }

      > ${Button} {
        margin-top: ${spacing.lg};
      }
    `
  },
)

const ErrorPage = ({
  label,
  onClick,
  href,
  text,
  variant: pageVariant = 'neutral',
  ...props
}: ErrorPageProps) => {
  return (
    <Container variant={pageVariant} className="errorPage" {...props}>
      <Icon name="info" size={6} />
      <Text variant="subtitle200">{text}</Text>

      {label && (
        <Button
          // @ts-expect-error
          forwardedAs={href && RemixLink}
          data-testid="error-page-button"
          /**
           * The Button should have a visible background,
           * therefore it should have the alternate color
           * option from the page's background
           */
          color={pageVariant === 'primary' ? 'neutral' : 'primary'}
          inline
          onClick={onClick}
          to={href}
        >
          {label}
        </Button>
      )}
    </Container>
  )
}

export default ErrorPage
